<template>
  <div class="icon-bg">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.3" cx="12.9178" cy="12" r="10" fill="#A7A9C0" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5088 13.209V14.868H12.7688C15.3098 14.868 17.3048 12.915 17.3048 10.395C17.3048 7.875 15.3098 5.922 12.7688 5.922C10.2278 5.922 8.25385 7.875 8.25385 10.395H10.0388C10.0388 8.799 11.1518 7.581 12.7688 7.581C14.3648 7.581 15.5198 8.799 15.5198 10.395C15.5198 11.991 14.3648 13.209 12.7688 13.209H11.5088ZM12.9178 16C12.3656 16 11.9178 16.4477 11.9178 17C11.9178 17.5523 12.3656 18 12.9178 18C13.4701 18 13.9178 17.5523 13.9178 17C13.9178 16.4477 13.4701 16 12.9178 16Z"
        fill="#A7A9C0"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconHelp",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.1s;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (hover: hover) {
    &:hover {
      background: #ebf2fa;
      svg {
        path {
          fill: #5551f9;
        }
      }
    }
  }
  &:active {
    background: transparent;
    svg {
      path {
        fill: #5551f9;
      }
    }
  }
}
</style>
